import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";

import { useCurrentImageContext } from './CurrentImageContext';
import NoImage from '../../components/Icons/NoImage';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import './imageZoom.css';

function ProductGalleryCurrentImage({ media, title, gatsbyImageProps }) {
  const { currentImageIndex } = useCurrentImageContext();
  const currentImage = media[currentImageIndex];

  return <>
    {media && media.length > 0 ? (
      <Zoom
        transitionDuration={200}
        overlayBgColorEnd='rgba(255, 255, 255, 0.8)'
        overlayBgColorStart="rgba(0, 0, 0, 0)"
        zoomMargin={20}
      >
        <GatsbyImage
          image={currentImage.image.localFile.childImageSharp.main}
          alt={currentImage.image.altText ? currentImage.image.altText : title}
          style={{ 
            maxWidth: 1300,
            width: '100%'
          }}
          data-product-image
          {...gatsbyImageProps} />
      </Zoom>
    ) : (
      <NoImage
        color="grey"
        p={4}
        width="500px"
        height="500px"
        sx={{
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'grey',
        }}
      />
    )}
  </>;
}
export default ProductGalleryCurrentImage;
