import React from 'react';
import { ProductJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo';

function getSizeList(options) {
  if (!options || options.length < 1) return '';
  const sizeOption = options.find(option => option.name === "Size")

  return sizeOption && sizeOption.values ? sizeOption.values.join("/ ") : ''
}

const SEOProductJsonLd = (props) => {
  const {
    data: {
      product: {
        id,
        title,
        description,
        media,
        variants,
        options,
        vendor,
        productType,
      },
      store: {
        siteMetadata: {
          gatsbyStorefrontConfig: {
            storeName
          },
        },
      },
    },
    location: {
      href,
      origin,
    },
  } = props;

  const {
    price : {
      amount,
      currencyCode,
    }
  } = variants[0]

  return (
    <>
      <GatsbySeo
        title={title}
        titleTemplate={`%s — ${storeName}`}
        openGraph={{
          type: 'product',
          url: href,
          title: title,
          description: description,
          images: media && media.map(image =>
            ({
              url: origin + image.image.localFile.childImageSharp.main.src,
            })
          )
        }}
      />
      <ProductJsonLd
        productName={title}
        images={
          media && media.map(image =>
            ({
              url: origin + image.image.localFile.childImageSharp.main.src,
            })
          )
        }
        product_type={productType}
        description={description}
        brand={vendor}
        offers={{
          price: amount,
          priceCurrency: currencyCode,
          itemCondition: 'http://schema.org/NewCondition',
          availability: 'http://schema.org/PreOrder',
          url: href,
          seller: {
            name: vendor,
          },
          size: getSizeList(options),
          size_type: 'regular',
          size_system: 'EU',
          gender: 'unisex',

        }}
        mpn={id}
      />
    </>
  )
}

export default SEOProductJsonLd;
