import React, { useState } from 'react'
import { navigate } from 'gatsby-link'
import {
  Label,
  Input,
} from '@rebass/forms'
import { Box, Button, Flex } from 'rebass'
import { Icon, InlineIcon } from '@iconify/react';
import baselineInfo from '@iconify-icons/ic/baseline-info';


function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const SignupPreorder = ({ description }) => {
  const [formData, setFormData] = useState({})
  const [success, setSuccess] = useState(false)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const successful = () => {
    setSuccess(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formData,
      }),
    })
      .then(() => successful())
      .catch((error) => alert(error))
  }

  return (
    <>
      <Box
        as='form'
        onSubmit={handleSubmit}
        name="contact"
        method="post"
        action="/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>

        <Flex
          flexDirection="row"
          width={1}
          mb={2}
        >
          <Box width={1 / 7}>
            <Icon
              icon={baselineInfo}
              width='3em'
            />
          </Box>
          <Box width={6 / 7}>
            {description}
          </Box>
        </Flex>
        { success ?
        (
          <Box
            width={1}
            mb={2}
            mt={4}
          >
         You successfully signed up. Thank you! We will talk soon.
          </Box>
        ) : (
        <>
          <Flex
            flexDirection="row"
            width={1}
            mb={2}
          >
            <Box width={2 / 10}>
              <Label htmlFor='name'>
                Name:
              </Label>
            </Box>
            <Box width={8 / 10}>
              <Input
                id='name'
                name='name'
                placeholder='John Doe'
                onChange={handleChange}
              />
            </Box>
          </Flex>
          <Flex
            flexDirection="row"
            width={1}
            mb={2}
          >
            <Box width={2 / 10}>
              <Label htmlFor='email'>
                E-mail:
              </Label>
            </Box>
            <Box width={8 / 10}>
              <Input
                id='email'
                name='email'
                type='email'
                placeholder='john@example.com'
                onChange={handleChange}
                required
              />
            </Box>
          </Flex>

          <Button
            ml="auto"
            width={['300px', '400px']}
            variant='primary'
          >
            {'Sign up'}
          </Button>
        </>
      )
    }
      </Box>
    </>
  )
}

export default SignupPreorder;
