import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import ProductPage from './ProductPage';
import Layout from '../../components/Layout';

const Product = (props) => {
  const { title } = props.data.product;
  const { storeName } = props.data.store.siteMetadata.gatsbyStorefrontConfig;

  return (
    <Layout>
      <Helmet title={title} titleTemplate={`%s — ${storeName}`} defer={false} />
      <ProductPage {...props} />
    </Layout>
  );
};
export default Product;

export const productQuery = graphql`query SingleProductQuery($handle: String!) {
  product: shopifyProduct(handle: {eq: $handle}) {
    shopifyId
    title
    description
    descriptionHtml
    vendor
    productType
    tags
    media {
      ... on ShopifyMediaImage {
        id
        image {          
          altText
          localFile {
            childImageSharp {
              main: gatsbyImageData(breakpoints: [400, 800, 1300, 2000], layout: FULL_WIDTH)
              thumbnail: gatsbyImageData(
                width: 140
                height: 140
                backgroundColor: "white"
                breakpoints: [90, 140]
                transformOptions: {cropFocus: ATTENTION, fit: COVER}
                layout: CONSTRAINED
              )
            }
      		}
        }
      }
    }
    variants {
      availableForSale
      compareAtPrice
      title
      shopifyId
      sku
      weight
      weightUnit
      selectedOptions {
        name
        value
      }
      price
    }
    options {
      shopifyId
      name
      values
      position
    }
  }
  catalog: shopifyCollection(products: {elemMatch: {handle: {eq: $handle}}}) {
    title
    handle
    fields {
      shopifyThemePath
    }
  }
  store: site {
    siteMetadata {
      gatsbyStorefrontConfig {
        storeName
        payments
        shareButtons
        enablePreorder
        gatsbyImageProps {
          loading
          fadeIn
          durationFadeIn
        }
      }
    }
  }
}
`;
