/** @jsx jsx */
/* eslint no-unused-vars: 0 */
import React from 'react';
import { jsx } from 'theme-ui';
import styled from '@emotion/styled';
import { useCurrentImageContext } from './CurrentImageContext';
import { GatsbyImage } from "gatsby-plugin-image";
import { Box } from 'rebass';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { Icon } from '@iconify/react';
import chevronLeft from '@iconify-icons/oi/chevron-left'  ;
import chevronRight from '@iconify-icons/oi/chevron-right';
import strings from './strings.json';

const ThumbnailImage = styled(GatsbyImage)(
  ({ currentImageIndex, index }) => ({
    transition: '0.5s ease all',
    cursor: 'pointer',
    border: `1px solid ${
      currentImageIndex !== index ? 'transparent' : 'black'
    }`,
    marginRight: '5px'
  })
);

const ProductGalleryThumbnails = ({
  media,
  title,
  maxImageHeight = 135,
  maxImageWidth = 135,
  maxContainerHeight = 140,
  visibleSlides = 5,
  gatsbyImageProps,
}) => {
  const { currentImageIndex, setCurrentImageIndex } = useCurrentImageContext();
  const { ariaNextButtonLabel, ariaBackButtonLabel } = strings;

  return (
    <Box width={1} sx={{ position: 'relative' }}>
      <CarouselProvider
        orientation='horizontal'
        visibleSlides={visibleSlides}
        totalSlides={media.length}
        step={1}
        naturalSlideWidth={maxImageWidth}
        naturalSlideHeight={maxImageHeight}
      >
        <Slider
          sx={{
            height: {maxContainerHeight},
            width: {maxImageWidth},
            position: 'relative'
          }}
        >
          {media.map((image, index) => (
            <Slide
              key={index}
              index={index}
              onClick={() => setCurrentImageIndex(index)}
            >
              <ThumbnailImage
                key={image.id}
                index={index}
                currentImageIndex={currentImageIndex}
                image={image.image.localFile.childImageSharp.thumbnail}
                alt={image.image.altText ? image.image.altText : title}
                {...gatsbyImageProps}
              />
            </Slide>
          ))}
        </Slider>
        {visibleSlides < media.length ? (
          <>
          <ButtonBack
            aria-label={ariaBackButtonLabel}
            sx={{
              position: 'absolute',
              top: '50%',
              'z-index': '2',
              border: 0,
              bg: 'transparent',
              color: 'primary',
              opacity: 0.5,
              backgroundColor: 'gray',
              borderRadius: '2px',
              ':hover,:focus,.active': {
                opacity: 0.8,
              },
              left: '0',
              marginRight: '0',
              transform: 'translate(10%, -50%)',
            }}
          >
            <Icon icon={chevronLeft} rotate={0} />
          </ButtonBack>
          <ButtonNext
            aria-label={ariaNextButtonLabel}
            sx={{
              position: 'absolute',
              bottom: '50%',
              'z-index': '2',
              border: 0,
              bg: 'transparent',
              color: 'primary',
              opacity: 0.5,
              backgroundColor: 'gray',
              borderRadius: '2px',
              ':hover,:focus,.active': {
                opacity: 0.8,
              },
              left: '100%',
              marginRight: '0',
              transform: 'translate(-125%, 50%)',
            }}
          >
            <Icon icon={chevronRight}  rotate={0} />
          </ButtonNext>
        </>
        ) : ( ' ' )
      }
      </CarouselProvider>
    </Box>
  );
}

export default ProductGalleryThumbnails;
